.menutop {
  width: 100%;
  text-align: center;
  padding: 30px 20px 30px;
  color: #ffffff;
  background-color: #f97d07;

  width: 100%;

  /* border-radius: 20px; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.menubg {
  background-color: #ff890b;
  background-image: url("../images/menubg5.svg");
  background-repeat: repeat;
  background-position: fixed;
  background-size: cover;
}
.whitecolor {
  color: #ffffff;
}
.menutop h1 {
  color: #414141;
  font-size: 70px;
}
.menutop p {
  color: #fed92b;
  font-weight: 600;
  font-size: 48px;
}
.menugrid {
  width: 100%;
  padding: 40px 10px;
  /* background-color: #f6d7b8; */
  margin-bottom: 20px;
}
.dishcardcontent ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.aboutdish {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.aboutdish p {
  font-weight: 600;
}
.aboutdishdetails p {
  font-size: 12px;
  color: #5b5b5b;
  font-weight: 600;
}
.orangetext {
  color: #f88001;
}
.menudishgrid {
  align-items: stretch !important;
  gap: 50px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1000px) {
  .menutop h1 {
    font-size: 36px;
  }
  .menutop p {
    color: #fed92b;
    font-weight: 600;
    font-size: 24px;
  }
}
