.navbar {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
  background-color: #ffffff;
  z-index: 10000;
}
.navbarleft {
  display: flex;
  align-items: center;
  height: 60px;
}
.navbarleft img {
  height: 100%;
}
.navbarright button {
  background-color: #d6e452;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
}
.navbarright {
  display: flex;
  align-items: center;
  gap: 10px;
}
