.footer {
  width: 90%;

  margin: 0 auto;
}
.footer hr {
  border: 1px solid #f76a03;
  height: 2px;
  background-color: #f76a03;
}
.footerbottom {
  display: flex;
  font-size: 14px;
  align-items: center;
  padding: 10px 20px 20px;
  justify-content: space-between;
}

.footerbottom ul {
  list-style-type: none;
  display: flex;
  color: #f76a03;
  gap: 20px;
}
.footerbottom ul li {
  cursor: pointer;
}

.footerbottomcenter ul {
  list-style: none;
  display: flex;
  align-items: center;
  color: black;
  font-size: 20px;
}
.chatwhatsapp {
  background-color: #24d366;
  color: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 10px;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 30px;
}
.footerbottom a {
  text-decoration: underline;
  color: #000;
}
.chatwhatsapp p {
  font-weight: 500;
  font-size: 16px;
}
.whatsapp-button {
  position: fixed;

  width: 60px;
  height: 60px;
  border-radius: 100%;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  align-items: center;
  z-index: 100;
  justify-content: center;
}
.whatsapp-button {
  bottom: 20px;
  right: 20px;
  background-color: #24d366;
}
.whatsapp-button a {
  height: 100%;
  color: #ffffff;
  display: flex;
  font-size: 35px;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1000px) {
  .footerbottom {
    flex-direction: column-reverse;
    gap: 10px;
  }
}
