@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.5;
}
.centeralign {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

button {
  cursor: pointer;
}
