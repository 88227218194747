.homemaintop {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  gap: 50px;
  height: calc(100vh - 80px);
}
.homemaintopleft {
  width: 60%;
}
.lefttoplogo {
  height: 100px;
  width: 100px;
}
.homemaintopright {
  width: 40%;
}
.homemaintopright {
  display: flex;
  border-radius: 50%;

  height: 100%;
  position: relative;
}
.homemaintoprightbg {
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: #f6a940;
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.homemaintopright img {
  height: 70%;
  box-shadow: 10px 0px 80px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  aspect-ratio: 1/1;
  position: absolute;
  object-fit: cover;
  z-index: 10;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}
.homemaintopleft {
  padding-left: 50px;
}
.homemaintopleft h1 {
  font-size: 48px;
  font-weight: 500;
}
.homemaintopleft h1 strong {
  color: #f76a03;
}

.homemaintopleft p {
  font-size: 24px;
  font-weight: 600;
  margin-left: 5px;
}

.menubutton {
  background-color: #f76a03;
  color: white;

  border: none;
  padding: 10px 25px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 50px auto;
}
.menubutton:hover {
  transform: scale(1.05);
  background-color: #f55304;
}
.homemaindishes {
  background-color: #f6d7b8;
  padding: 50px;
}
.homemaindishesheader p {
  font-weight: 700;
}
.dishcard h3 {
  font-size: 24px;
}
.sectionheaders {
  text-align: center;
  font-size: 64px;
  margin-bottom: 20px;
}
.sectionheaders strong {
  color: #f76a03;
}
.homemaindishesheader p {
  font-size: 20px;
  text-align: center;
}

.dishgrid {
  display: flex;
  max-width: 1300px;
  gap: 20px;
  margin: 50px auto 20px;
  align-items: center;
  justify-content: center;
}
.dishcard {
  width: 25%;
  background-color: #fefefe;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 20px 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.dishcard img {
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
  height: 250px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.dishcardcontent p {
  text-align: left;
  margin-top: 10px;
  font-weight: 400;
  font-size: 16px;
}
.dishrating,
.dishcardfooter {
  display: flex;
  gap: 2px;
  align-items: center;
}
.dishcardfooter {
  margin-top: 20px;
  justify-content: space-between;
}

.dishcardfooter p {
  color: #007a3d;
}
.dishrating {
  color: #efcc43;
}
.dishrating p {
  color: #d8b00e;
}
.homemainabout {
  background-color: #f9fbdc;
  display: flex;
  gap: 80px;
  align-items: center;
}
.homemainaboutleft,
.homemainaboutright {
  width: 50%;
}
.homemainaboutright {
  padding-right: 50px;
}
.homemainaboutright p {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
}
.homemainaboutleftbg {
  height: 60vh;
  width: 80%;
  z-index: 1;
  display: flex;
  align-items: center;
  clip-path: polygon(0 0, 0% 100%, 100% 49%);
  background-color: #f76a03;
}
.homemainaboutleft {
  padding: 50px 0px;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.homemainaboutleft img {
  height: 100%;

  z-index: 1000;
  position: absolute;
  aspect-ratio: 1/1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 80%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.homebottom {
  width: 90%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  margin: 40px auto;
  background-color: #f76a03;
  border-radius: 30px;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}
.homebottom h1 {
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ffffff;
}
.homebottom p {
  color: #ffeee2;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 10px;
}
.homebottom strong {
  margin-top: 20px;
  text-align: center;
  width: 70%;
  font-size: 20px;
  color: #ffffff;
}
.contactbutton {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: none;
  color: #000;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 20px;
  font-weight: 600;
  gap: 5px;
  transition: all 0.3s ease;
}
.contactbutton:hover {
  transform: scale(1.05);
}
.homemainaboutleft img {
  background-position: center;
}
.homesubscription {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ffdbab;
  gap: 30px;
  padding: 40px;
}
.homesubscriptionleft {
  width: 60%;
}
.homesubscriptionleft p {
  font-size: 22px;
  font-weight: 500;
}
.homesubscriptionright {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homesubscriptionright .dishcard {
  width: 100%;
  max-width: 450px;
}
.centeralign {
  margin: 20px auto;
  width: fit-content;
}
@media screen and (max-width: 1000px) {
  .homemaintop {
    flex-direction: column;
  }
  .homemaintopleft,
  .homemaintopright {
    width: 100%;
    height: 50%;
  }
  .homemaintopleft {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .homemaintoprightbg {
    clip-path: none;
  }
  .homemaintopright img {
    height: 100%;
    aspect-ratio: 1/1;
    max-width: 100%;
    object-fit: cover;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .lefttoplogo {
    margin: 10px auto;
  }
  .dishgrid {
    flex-wrap: wrap;
  }
  .homemaintopleft h1 {
    font-size: 44px;
    text-align: center;
  }
  .homemaintopleft p {
    text-align: center;
  }
  .menubutton {
    margin: 20px auto;
  }
  .dishcard {
    width: 100%;

    max-width: 300px;

    margin-bottom: 20px;
  }
  .dishcardcontent p {
    font-size: 14px;
  }
  .sectionheaders {
    font-size: 36px;
  }

  .homemaindishesheader p {
    font-size: 16px;
  }
  .homemainabout {
    flex-direction: column-reverse;
  }
  .homemainaboutleft,
  .homemainaboutright {
    width: 100%;
    height: 50%;
  }
  .homemainaboutleft {
    padding: 0px;
    display: flex;

    flex-direction: column;
    justify-content: center;
  }
  .homemainaboutright {
    padding: 40px;
  }
  .homemainaboutleftbg {
    height: 30vh;
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 100%, 0% 100%);
    width: 100%;
  }
  .homemainaboutleft img {
    top: 20%;
    left: 50%;
    /* width: 100%; */
    max-width: 70%;
    height: auto;
    max-height: 100%;
  }
  .homebottom h1 {
    font-size: 24px;
  }
  .homebottom strong {
    width: 90%;
    font-size: 16px;
  }
  .homesubscription {
    flex-direction: column;
  }
  .homesubscriptionleft {
    width: 100%;
  }
  .homesubscriptionright {
    width: 100%;
  }
  .homesubscriptionright .dishcard {
    max-width: 400px;
  }
}

@media screen and (min-width: 1200px) {
  .homemaintopleft h1 {
    font-size: 78px;
  }
}
